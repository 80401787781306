import React, { FC } from 'react';
import { useConfig } from '~/resources';
import { Post, Tag as TagModel } from '~/resources/models';
import { generateDateStringRuLocale } from '~/utils/datetime';
import Title from '~/components/typography/Title';
import Paragraph from '~/components/typography/Paragraph';
import CommonLink from '~/components/Links/CommonLink';
import { Tag } from '~/components/Tag';
import Image from '../../Image';
import Topic from '../../Topic';
import c from './HeaderPost.sass';
import Rubric from '../../Rubric';

type TProps = {
  post: Post;
}

const HeaderPost: FC<TProps> = ({
  post: {
    title, description, image = { alt: '' }, authors, topic, published_at, image_title, partner_material, rubric, tags,
  },
}) => {
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const topicSubtitle = generateDateStringRuLocale(new Date(+published_at * 1000));

  return (
    <div className={c.container}>
      <div className={c.wrapper}>
        <div className={c.labels}>
          {topic && topic.title && (
            <Topic
              title={topic.title}
              subtitle={topicSubtitle}
              href={`/topic/${topic.slug}`}
              colorOrange={!!partner_material}
              size="lg"
            />
          )}
          {rubric && <Rubric name={rubric.name} href={`/rubric/${rubric.slug}`} size="lg" />}
          {tags.map((tag: TagModel) => (
            <Tag key={tag.slug} name={tag.name} href={tag.slug} color={tag.color} size="lg" />
          ))}
        </div>
        <Title level={1} className={c.title}>{title}</Title>
        <Title level={4} className={c.description}>{description}</Title>
      </div>
      {(Array.isArray(authors) && authors.length > 0) && (
        <div className={c.footer}>
          <div className={c.authors}>
            <Paragraph variant="caption" className={c.authors__title}>
              {(authors.length === 1) ? 'Автор:' : 'Авторы:'}
            </Paragraph>
            <div>
              {authors.map((el, i) => (
                <CommonLink to={`/author/${el.id}`} key={el.name}>
                  <Paragraph variant="caption">
                    {el.name}
                    {el.position ? ` (${el.position})` : ''}
                  </Paragraph>
                </CommonLink>
              ))}
            </div>
          </div>
        </div>
      )}
      {partner_material?.image ? (
        <div className={c.footer}>
          <div className={c.authors} style={{ alignItems: 'center' }}>
            <div className={c.authors_reminder_plus}>Автор</div>
            <div>Reminder+</div>
            {partner_material.link ? (
              <a href={partner_material.link} target="_blank" rel="noreferrer">
                <img
                  className={c.imagePartnerLogo}
                  src={partner_material.image.src}
                  height={partner_material.logo_height}
                  alt=""
                />
              </a>
            ) : (
              <img
                className={c.imagePartnerLogo}
                src={partner_material.image.src}
                height={partner_material.logo_height}
                alt=""
              />
            )}
          </div>
        </div>
      ) : partner_material && (
        <div className={c.footer}>
          <div className={c.authors}>
            <div className={c.authors_reminder_plus}>Автор</div>
            <div>Reminder+</div>
          </div>
        </div>
      )}
      {image && (
        <Image
          {...image}
          mode="cover"
          className={c.image}
          imageConfig={imageConfig}
          alt={image.alt || title}
        />
      )}
      { image_title && (
        <Paragraph variant="caption" className={c.image_title}>
          {image_title}
        </Paragraph>
      )}
    </div>
  );
};

HeaderPost.displayName = 'HeaderPost';
export default HeaderPost;
