import React, { FC } from 'react';
import { useConfig } from '~/resources';
import { Post, Tag as TagModel } from '~/resources/models';
import Title from '~/components/typography/Title';
import Paragraph from '~/components/typography/Paragraph';
import { PostType } from '~/resources/PostType';
import { generateDateStringRuLocale } from '~/utils/datetime';
import envconfig from '~/config';
import CommonLink from '~/components/Links/CommonLink';
import Image from '../Image';
import Topic from '../Topic';
import c from './PreviewPost.sass';
import Rubric from '../Rubric';
import { Tag } from '../Tag';

type TProps = {
    post: Post;
    showRubricLabel?: boolean;
}

const PreviewPost: FC<TProps> = ({
  post, showRubricLabel = true,
}) => {
  const {
    topic, published_at, title, description,
    image_preview, preview_mode = 'preview', slug, rubric,
  } = post;
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const isGuide = post.type === PostType.GUIDE;
  const isSpecial = post.type === PostType.SPECIAL;

  const urlPost = isSpecial
    ? `${envconfig('APP_HOST')}/special/${slug}`
    : `/post/${slug}`;
  let urlTopic = isGuide ? '/guides' : (topic && `/topic/${post.topic.slug}`);
  urlTopic = isSpecial ? '/' : urlTopic;
  const urlRubric = rubric && `/rubric/${rubric.slug}`;

  let topicTitle = isGuide ? 'Гайды Reminder' : topic?.title;
  topicTitle = isSpecial ? 'SPECIAL' : topicTitle;

  const topicSubtitle = generateDateStringRuLocale(new Date(+published_at * 1000));

  return (
    <div className={`${c.container} ${c[`container_${preview_mode}`]}`}>
      <div className={c.content}>
        {(topicTitle) && (
          <div className={c.top}>
            <Topic
              title={topicTitle}
              subtitle={topicSubtitle}
              href={urlTopic}
              colorOrange={!!post.partner_material}
              isGuide={isGuide}
            />
            {showRubricLabel && rubric && <Rubric name={rubric.name} href={urlRubric} />}
            {post?.tags.map((tag: TagModel) => (
              <Tag key={tag.slug} name={tag.name} href={tag.slug} color={tag.color} />
            ))}
          </div>
        )}
        {isSpecial ? (
          <a href={urlPost}>
            <Title level={2} className={c.title}>{title}</Title>
          </a>
        ) : (
          <CommonLink to={urlPost} className={c.title}>
            <Title level={2} className={c.title}>{title}</Title>
          </CommonLink>
        )}
        {description && (
          <div className={c.description}>
            <Paragraph>{description}</Paragraph>
          </div>
        )}
      </div>
      <div className={c.imageWrap}>
        {description && (
          <div className={`${c.description} ${c.description_mob}`}>
            <Paragraph>{description}</Paragraph>
          </div>
        )}
        {image_preview && (
          <>
            {isSpecial ? (
              <a href={urlPost}>
                <Image
                  {...image_preview}
                  mode={preview_mode}
                  className={c[`place-${preview_mode}`]}
                  imageConfig={imageConfig}
                  contentEditable={undefined}
                  alt={image_preview.alt || title}
                />
              </a>
            ) : (
              <CommonLink to={urlPost}>
                <Image
                  {...image_preview}
                  mode={preview_mode}
                  className={c[`place-${preview_mode}`]}
                  imageConfig={imageConfig}
                  contentEditable={undefined}
                  alt={image_preview.alt || title}
                />
              </CommonLink>
            )}
          </>
        )}
      </div>
    </div>
  );
};

PreviewPost.displayName = 'PreviewPost';
export default PreviewPost;
