import React, { FC } from 'react';
import Title from '~/components/typography/Title';
import SearchIcon from '~/components/icons/Search';
import clsx from 'clsx';
import { usePageContext } from '~/components';
import HamburgerIcon from '~/components/icons/Hamburger';
import Logo from '~/components/icons/Logo';
import StarIcon from '~/components/icons/Star';
import useStickyHeader from '~/components/page/header/hooks/useStickyHeader';
import { PAGE_HEADER_ID } from '~/utils/ids';
import LoginPage from '~/components/page/LoginPage';
import ProfileHeader from '~/components/page/header/ui/ProfileHeader';
import { FetchStatus } from '~/resources';
import { useLocation } from 'react-router';
import CommonNavLink from '~/components/Links/CommonNavLink';
import c from './PageHeader.sass';

type TProps = {
  dark?: boolean;
}

const PageHeader: FC<TProps> = ({ dark }) => {
  const {
    profile, hideMenu, profileStatus,
  } = usePageContext();
  const {
    top, topFix, headerRef, scroll, bannerHeight,
  } = useStickyHeader();
  const { pathname } = useLocation();

  const isSubscribed = profile && profile[0] && profile[0].is_subscribed;
  const status = profileStatus?.[0] ?? undefined;
  const isLoading = !status || status === FetchStatus.Fetching;
  const isSubscriptionPage = pathname === '/subscription';

  const toggleMenu = () => {
    if (!hideMenu) return;

    hideMenu[1]((isOpen) => !isOpen);
  };

  return (
    <div
      className={clsx(c.page_header, {
        [c.page_header__dark]: dark,
        [c.page_header__top_fix]: topFix,
        [c.with_banner]: bannerHeight && scroll <= bannerHeight,
      })}
      ref={headerRef}
      style={{ top }}
      id={PAGE_HEADER_ID}
    >
      <CommonNavLink
        to="/"
        className={`${c.page_header__mobile} ${dark ? c.page_header__mobile_dark : ''}`}
        aria-label="На главную"
      >
        <Logo />
      </CommonNavLink>
      <button
        type="button"
        className={clsx(c.hamburger_container, c.page_header__mobile, dark ? c.page_header__mobile_dark : '')}
        onClick={toggleMenu}
        aria-label="Menu button"
      >
        <HamburgerIcon />
      </button>
      <div className={clsx(c.links_container, c.page_header__desktop)}>
        <CommonNavLink
          to="/search"
          className={c.link}
        >
          <SearchIcon />
          <Title level={4} notHeader>Поиск</Title>
        </CommonNavLink>
      </div>
      <div className={clsx(c.links_container, c.page_header__desktop)}>
        {!isSubscribed && !isLoading && !isSubscriptionPage && (
          <CommonNavLink
            to="/subscription"
            className={clsx(c.link, c.link__subscription)}
          >
            <StarIcon />
            <Title level={4} notHeader>Подписка Reminder</Title>
          </CommonNavLink>
        )}
        <LoginPage inHeader />
        <ProfileHeader />
      </div>
    </div>
  );
};

PageHeader.displayName = 'PageHeader';
export default PageHeader;
