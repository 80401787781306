import React, { forwardRef } from 'react';
import CommonLink from '~/components/Links/CommonLink';
import { Image, Topic } from '~/components';
import { Post } from '~/resources/models';
import { useConfig } from '~/resources';
import c from './longread.sass';

type Props = {
  post: Post;
}

const PreviewLongread = forwardRef<HTMLDivElement, Props>((
  {
    post: {
      slug, title, subtitle, published_at, image_preview, status,
    },
  },
  ref,
) => {
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const isAnons = status === 'announce' || !published_at;

  const render = () => (
    <>
      <div className={c.preview_bg}>
        {image_preview && (
        <Image
          {...image_preview}
          mode="preview_longread"
          imageConfig={imageConfig}
          contentEditable={undefined}
          alt={image_preview.alt || title}
        />
        )}
      </div>
      <div className={c.preview_wrap}>
        <div className={c.preview_topic}>
          {isAnons ? (
            <Topic title="Скоро" />
          ) : (
            <Topic
              showDot
              title="Обновлено"
              subtitle={new Date(+published_at * 1000).toLocaleDateString('ru')}
            />
          )}
        </div>
        <div className={c.preview_title}>{subtitle || title}</div>
      </div>
    </>
  );

  return (
    <div className={c.container} ref={ref}>
      {isAnons ? (
        <div className={c.preview}>
          {render()}
        </div>
      ) : (
        <CommonLink className={c.preview} to={`/longread/${slug}`}>
          {render()}
          <div className={c.corner}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g fill="#b688e4"><g><path d="M0,24A14.44,14.44,0,0,0,7,13a7.78,7.78,0,0,1,7-7c8-1,11-6,11-6l2,2L2,26Z"/></g></g></svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g fill="#b688e4"><g><path d="M0,27s11-2,11-10a7,7,0,0,1,7-7C26,10,28,0,28,0l3,6L6,30Z" /></g></g></svg>
          </div>
        </CommonLink>
      )}
    </div>
  );
});

PreviewLongread.displayName = 'PreviewLongread';
export default PreviewLongread;
