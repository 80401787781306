import React, { FC, useEffect } from 'react';
import useTags from '~/resources/useTags';
import { Loader } from '~/components';
import { Tag as TagModel } from '~/resources/models';
import c from './TagList.sass';
import Tag from './Tag';

const TagList: FC = () => {
  const { fetchBy, data: tags } = useTags();

  useEffect(() => fetchBy(), []);

  if (!tags) {
    return <Loader />;
  }

  if (tags.length === 0) {
    return null;
  }

  return (
    <div className={c.container}>
      <p className={c.title}>Подборки самого лучшего</p>
      <div className={c.list}>
        {tags.map((tag: TagModel) => (
          <Tag key={tag.slug} name={tag.name} href={tag.slug} color={tag.color} />
        ))}
      </div>
    </div>
  );
};

TagList.displayName = 'TagList';
export default TagList;
