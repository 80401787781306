import React, { FC } from 'react';
import CommonLink from '~/components/Links/CommonLink';
import { Post } from '~/resources/models';
import c from './PreviewNews.sass';

type TProps = {
  post: Post;
};

const PreviewNews: FC<TProps> = ({ post }) => {
  const {
    topic, title, slug,
  } = post;

  const urlTopic = topic && `/topic/${post.topic.slug}`;
  const urlNews = `/post/${slug}`;

  return (
    <div>
      <CommonLink to={urlTopic} className={c.topic}>
        {topic.title}
      </CommonLink>
      <CommonLink to={urlNews} className={c.title}>
        {title}
      </CommonLink>
    </div>
  );
};

PreviewNews.displayName = 'PreviewNews';
export default PreviewNews;
