import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type TProps = {
  to: string;
  target?: string;
  className?: string;
}
const CommonLink: FC<TProps> = ({
  to, target = '', className = '', children,
}) => (
  <Link
    to={to}
    target={target}
    className={className}
  >
    {children}
  </Link>
);

CommonLink.displayName = 'CommonLink';
export default CommonLink;
