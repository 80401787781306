import React, {
  FC, useEffect, useState,
} from 'react';
import CommonLink from '~/components/Links/CommonLink';
import { Report } from '~/resources/models';
import ReportPrice from './ReportPrice';
import classes from './ReportPreview.sass';

interface TProps {
  report: Report;
  index: number;
}

const maxDescriptionLength = () => {
  if (typeof window === 'undefined') return 1000;

  switch (true) {
    case window.innerWidth < 640: return 1000;
    case window.innerWidth < 768: return 160;
    case window.innerWidth < 960: return 125;
    case window.innerWidth < 1024: return 165;
    default: return 1000;
  }
};

const ReportPreview: FC<TProps> = ({ report, index }) => {
  const {
    id, slug, cover_origin, images, title,
    active_price, pre_order_price, list_caption,
    description, status,
  } = report;

  const [displayMode, setDisplayMode] = useState<string>();

  useEffect(() => {
    if (typeof window === 'undefined') return undefined;

    const onResize = () => {
      switch (true) {
        case window.innerWidth < 640: {
          setDisplayMode('one_in_line');
          break;
        }
        case window.innerWidth < 850: {
          setDisplayMode('two_in_line');
          break;
        }
        default: setDisplayMode('other');
      }
    };
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const needSeparator = () => {
    switch (displayMode) {
      case 'two_in_line': return index > 1;
      case 'one_in_line': return index > 0;
      default: return index > 2;
    }
  };

  return (
    <div className={classes.reportPreview} key={id}>
      {needSeparator() && <div className={classes.reportPreview__hr} />}
      <CommonLink to={`/report/${slug}`} className={classes.link}>
        <img
          className={classes.img}
          src={cover_origin.src}
          alt={cover_origin.alt}
        />
      </CommonLink>

      <div className={classes.reportPreview__imgs}>
        {[cover_origin, ...images].map((imgItem) => (
          <img
            className={classes.reportPreview__imgItem}
            key={imgItem.id}
            src={imgItem.src}
            alt={cover_origin.alt}
          />
        ))}
      </div>

      <CommonLink className={classes.title} to={`/report/${slug}`}>
        {title}
      </CommonLink>

      <div className={classes.reportPreview__price}>
        <ReportPrice
          {...{
            active_price,
            pre_order_price,
            status,
          }}
        />
      </div>

      <div className={classes.reportPreview__code}>{list_caption}</div>
      <div className={classes.reportPreview__description}>
        {description && `${description.substring(0, maxDescriptionLength())}${maxDescriptionLength() < description.length ? '...' : ''}`}
      </div>
    </div>
  );
};

ReportPreview.displayName = 'ReportPreview';
export default ReportPreview;
