import React, { FC } from 'react';
import CommonLink from '~/components/Links/CommonLink';

import clsx from 'clsx';
import c from './Rubric.sass';

type TRubricSize = 'md' | 'lg';

interface TProps {
  name: string;
  href: string;
  size?: TRubricSize;
}

const Rubric: FC<TProps> = ({ name, href, size = 'md' }) => (
  <CommonLink
    to={href}
    className={clsx(c.rubric, c.rubric__name, {
      [c.rubric_size_lg]: size === 'lg',
    })}
  >
    {name}
  </CommonLink>
);

Rubric.displayName = 'Rubric';
export default Rubric;
