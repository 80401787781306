import React, {
  FC, MutableRefObject, useEffect, useRef, useState,
} from 'react';
import Modal from 'react-modal';

import img from '~/images/cat.gif';
import { MailingStatus } from '~/resources/useMailing';
import { usePageContext } from '~/components/page/context.page';
import { ymReachGoal } from '~/utils/ym';
import SubscriptionMessagesModel from './SubscriptionMessagesModal';
import CloseIcon from './CloseIcon';
import ArrowIcon from './ArrowIcon';
import { SubscriptionForm } from '..';
import c from './ModalSubscription.sass';
import {
  onLocalCheckSubscription,
  setSubscriptionKey,
  onLocalCheckSubscriber,
  setSubscriberKey,
} from '../utils';

interface TProps {
  refElement: MutableRefObject<HTMLDivElement | undefined>;
}

const ModalSubscription: FC<TProps> = ({ refElement }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [status, setStatus] = useState<MailingStatus>();
  const flag = useRef<boolean>(false);
  const { profile } = usePageContext();

  const onScroll = () => {
    if (refElement?.current && !isSubscription && !isSubscriber) {
      if (profile && profile[0] && profile[0].is_subscribed) { return; }
      const height = refElement.current.clientHeight;
      const passed = -refElement.current.getBoundingClientRect().y;
      const proc = passed / (height / 100);
      const footer = document.querySelector('#footer');
      const offsetTop = footer ? footer.getBoundingClientRect().y + 350 : 0;
      const isFooter = offsetTop <= window.innerHeight;

      // Сложная логика показа модалки
      if (!flag.current) {
        if (!isOpen && proc >= 30 && !isFooter) {
          setIsOpen(true);
          flag.current = true;
          ymReachGoal('newsletter_popup_show');
        }
      } else if (isFooter && isOpen) {
        setIsOpen(false);
      } else if (!isFooter && !isOpen) {
        setIsOpen(true);
        ymReachGoal('newsletter_popup_show');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [refElement, isOpen, isSubscription, isSubscriber]);

  useEffect(() => {
    setIsSubscription(onLocalCheckSubscription());
  }, []);

  useEffect(() => {
    if (onLocalCheckSubscriber()) {
      setIsSubscriber(true);
      setSubscriberKey();
    } else if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('utm_medium') === 'email') {
        setIsSubscriber(true);
        setSubscriberKey();
      }
    }
  }, []);

  if (isSubscription || isSubscriber) {
    return null;
  }

  const onClose = () => {
    setSubscriptionKey();
    setIsOpen(false);
    setIsSubscription(true);
    ymReachGoal('newsletter_popup_close');
  };

  const onSubscribe = (newStatus: MailingStatus) => {
    setStatus(newStatus);
  };

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onClose}
      overlayClassName={c.modal}
      className={c.modal__content}
      style={{ overlay: { zIndex: 10000 } }}
    >
      <div className={c.subscription}>
        <button className={c.subscription__button} type="button" onClick={onClose}>
          <CloseIcon />
        </button>

        <div className={c.subscription__content}>
          <div className={c.subscription__text}>
            <h1 className={c.subscription__title}>Быть на связи...</h1>

            <p className={c.subscription__description}>
              не так сложно. Оставьте свою почту
              — и мы будем раз в неделю по&nbsp;вторникам
              присылать вам 15&nbsp;полезных ссылок и советов.
            </p>
          </div>

          <img
            className={c.subscription__img}
            src={img}
            alt=""
            width="233px"
            height="205"
          />
        </div>

        <div className={c.subscription__form}>
          { status ? (
            <SubscriptionMessagesModel status={status} />
          ) : (
            <SubscriptionForm
              tags={['popup1']}
              onChangeStatus={onSubscribe}
            >
              <>
                <span>Подписаться</span>
                <ArrowIcon />
              </>
            </SubscriptionForm>
          ) }
        </div>

        <p className={c.subscription__footer}>
          Рассылка приходит два раза в неделю. Вы всегда сможете отписаться.
        </p>
      </div>
    </Modal>
  );
};

ModalSubscription.displayName = 'ModalSubscription';
export default ModalSubscription;
