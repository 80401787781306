import React, { FC } from 'react';
import { Post } from '~/resources/models';

import Paragraph from '~/components/typography/Paragraph';
import Title from '~/components/typography/Title';
import TopicBadge from '~/components/post/ui/TopicBadge';
import { generateDateStringRuLocale } from '~/utils/datetime';
import AuthorItem from '~/components/post/headers/AuthorItem';
import CommonLink from '~/components/Links/CommonLink';
import Image from '~/components/Image';
import { useConfig } from '~/resources';
import c from './HeaderGuide.sass';

type TProps = {
  post: Post;
};

const HeaderGuide: FC<TProps> = ({ post }) => {
  const {
    title, description, topic, rubric, published_at, image, authors, tags,
  } = post;

  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const publishString = `Опубликовано ${generateDateStringRuLocale(new Date(published_at * 1000), false)}`;
  const authorsTitle = authors.length > 1 ? 'Авторы' : 'Автор';

  return (
    <>
      <div className={c.header_section}>
        <div className={c.header_section__inner_container}>
          <CommonLink to="/guides">
            <div className={c.guide_badge}>
              <Paragraph variant="caption">Гайд Reminder</Paragraph>
            </div>
          </CommonLink>
          <Title level={1}>{title}</Title>
          <Title level={4}>{description}</Title>
          <div className={c.badges_container}>
            <TopicBadge title={topic.title} slug={topic.slug} type="topic" />
            {rubric && <TopicBadge title={rubric.name} slug={rubric.slug} type="rubric" />}
            {tags.map((tag) => <TopicBadge key={tag.slug} title={tag.name} slug={tag.slug} type="tag" />)}
          </div>
          <Paragraph variant="caption" className={c.published_date_label}>{publishString}</Paragraph>
          {image && (
            <Image
              {...image}
              mode="cover"
              className={c.image}
              imageConfig={imageConfig}
              alt={image.alt || title}
            />
          )}
        </div>
      </div>
      {!!authors.length && (
        <div className={c.authors_section}>
          <Title level={4} className={c.authors_section__title}>{authorsTitle}</Title>
          <div className={c.authors_section__authors_container}>
            {authors.map((author) => (
              <CommonLink to={`/author/${author.id}`} key={author.id}>
                <AuthorItem author={author} />
              </CommonLink>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

HeaderGuide.displayName = 'HeaderGuide';
export default HeaderGuide;
