import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

type TProps = {
  to: string;
  exact?: boolean;
  className?: string;
  activeClassName?: string;
  onClick?: () => void | undefined;
};

const CommonNavLink: FC<TProps> = ({
  to,
  exact = false,
  className = '',
  activeClassName = '',
  onClick = undefined,
  children,
}) => (
  <NavLink
    exact={exact}
    to={to}
    className={className}
    activeClassName={activeClassName}
    onClick={onClick}
  >
    {children}
  </NavLink>
);

CommonNavLink.displayName = 'CommonNavLink';
export default CommonNavLink;
