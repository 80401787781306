import React, { FC } from 'react';
import { useHistory } from 'react-router';
import CommonLink from '~/components/Links/CommonLink';
import { useConfig } from '~/resources';
import { Post } from '~/resources/models';
import { PostType } from '~/resources/PostType';
import Topic from '../Topic';
import Image from '../Image';

import c from './PostRecommend.sass';

type TProps = {
    post: Post;
}

const PostRecommend: FC<TProps> = ({
  post: {
    topic, published_at, title, image_preview, slug, preview_mode, partner_material,
  },
}) => {
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const history = useHistory();
  const prefix = preview_mode === 'preview_super' ? PostType.SUPER : PostType.POST;
  const urlPost = `/${prefix}/${slug}`;
  const urlTopic = topic && `/topic/${topic.slug}`;
  const onClickImage = () => history.push(urlPost);

  return (
    <div className={c.post}>
      {image_preview && (
        <div className={c.image}>
          <Image
            {...image_preview}
            mode="read_more"
            imageConfig={imageConfig}
            contentEditable={undefined}
            onClick={onClickImage}
            alt={image_preview.alt || title}
          />
        </div>
      )}
      <div className={c.topic}>
        <Topic
          title={topic.title}
          subtitle={new Date(+published_at * 1000).toLocaleDateString('ru')}
          href={urlTopic}
          colorOrange={!!partner_material}
        />
      </div>
      <CommonLink to={urlPost} className={c.title}>{title}</CommonLink>
    </div>
  );
};

PostRecommend.displayName = 'PostRecommend';
export default PostRecommend;
