import React, { FC } from 'react';
import CommonLink from '~/components/Links/CommonLink';
import { useConfig } from '~/resources';
import { Post } from '~/resources/models';
import { PostType } from '~/resources/PostType';
import envconfig from '~/config';
import Topic from '../Topic';
import Image from '../Image';
import c from './PostSearch.sass';

interface TProps {
  post: Post
}

const PostSearch: FC<TProps> = ({
  post: {
    topic, published_at, title, image_preview, slug, type,
  },
}) => {
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const isSpecial = type === PostType.SPECIAL;

  let urlPost = (type === PostType.LONGREAD)
    ? `/${type}/${slug}`
    : `/${PostType.POST}/${slug}`;
  if (isSpecial) {
    urlPost = `${envconfig('APP_HOST')}/${PostType.SPECIAL}/${slug}`;
  }
  const urlTopic = topic && `/topic/${topic.slug}`;

  return (
    <div className={c.post}>
      <div className={c.content}>
        <div className={c.top}>
          <Topic
            title={topic.title}
            subtitle={new Date(+published_at * 1000).toLocaleDateString('ru')}
            href={urlTopic}
          />
        </div>
        {isSpecial ? (
          <a href={urlPost} className={c.title}>{title}</a>
        ) : (
          <CommonLink to={urlPost} className={c.title}>{title}</CommonLink>
        )}
      </div>
      <div className={c.imageWrap}>
        {image_preview && (
          <>
            {isSpecial ? (
              <a href={urlPost} className={c.image}>
                <Image
                  {...image_preview}
                  mode="preview"
                  imageConfig={imageConfig}
                  contentEditable={undefined}
                  alt={image_preview.alt || title}
                />
              </a>
            ) : (
              <CommonLink to={urlPost} className={c.image}>
                <Image
                  {...image_preview}
                  mode="preview"
                  imageConfig={imageConfig}
                  contentEditable={undefined}
                  alt={image_preview.alt || title}
                />
              </CommonLink>
            )}
          </>
        )}
      </div>
    </div>
  );
};

PostSearch.displayName = 'PostSearch';
export default PostSearch;
