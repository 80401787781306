import React, { FC } from 'react';
import Paragraph from '~/components/typography/Paragraph';
import clsx from 'clsx';
import CommonLink from '~/components/Links/CommonLink';
import c from './TopicBadge.sass';

type TProps = {
  title: string;
  slug: string;
  type?: 'topic' | 'rubric' | 'tag';
};
const TopicBadge: FC<TProps> = ({
  title, slug, type = 'rubric',
}) => {
  const href = `/${type}/${slug}`;

  return (
    <CommonLink to={href}>
      <div className={c.topic_badge}>
        <div className={clsx(
          c.topic_badge__dot,
          { [c.topic_badge__dot_is_topic]: type === 'topic' },
          { [c.topic_badge__dot_is_tag]: type === 'tag' },
        )}
        />
        <Paragraph variant="caption">{title}</Paragraph>
      </div>
    </CommonLink>
  );
};

TopicBadge.displayName = 'TopicBadge';
export default TopicBadge;
