import React, { FC } from 'react';
import CommonLink from '~/components/Links/CommonLink';
import clsx from 'clsx';

import c from './Tag.sass';

type TTagSize = 'md' | 'lg';
interface TProps {
    name: string;
    href: string;
    color: string;
    size?: TTagSize;
}

const Tag: FC<TProps> = ({
  name, href, color, size,
}) => (
  <CommonLink
    to={`/tag/${href}`}
    className={clsx(c.tag, c[`tag__color_${color}`], {
      [c.tag_size_lg]: size === 'lg',
    })}
  >
    { name }
  </CommonLink>
);

Tag.displayName = 'Tag';
export default Tag;
