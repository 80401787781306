import React, { FC, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Page, Paginator } from '~/components';
import TagPostList from '~/components/post/TagPostList';
import config from '~/config';
import Helmeter from '~/screens/Helmeter';
import JsonLd from '~/screens/jsonLd';
import Banner from '~/components/Banner';
import useTag from '~/resources/useTag';
import { Tag } from '~/resources/models';
import { TBreadcrumbs } from '~/utils/jsonLd/types';
import { BREADCRUMB_MAIN } from '~/utils/jsonLd/breadcrumbs';
import usePosts from '~/resources/usePosts';
import { QueryParams } from '~/resources';
import { PostType } from '~/resources/PostType';

type Params = {
    tag: string
}

const TagScreen: FC = () => {
  const { tag } = useParams<Params>();
  const canonicalLink = `${config('APP_HOST')}/${`tag/${tag}`}`;
  const location = useLocation();
  const { data: tagRes } = useTag(tag);
  const params: QueryParams = { type: [PostType.ARTICLE, PostType.GUIDE], page: String(1) };
  params.tag_id = tag;
  const { data: posts } = usePosts(params);

  const tagTitle = tagRes?.meta_title || tagRes?.name || tag;

  const [breadcrumbs, setBreadcrumbs] = useState<TBreadcrumbs>([
    BREADCRUMB_MAIN,
    {
      name: `Тег — ${tagTitle}`,
      href: location.pathname,
    },
  ]);

  const handleTagLoaded = (tagData: Tag) => {
    const tagName = tagData?.meta_title || tagData?.name || tag;
    setBreadcrumbs([
      BREADCRUMB_MAIN,
      {
        name: `Тег — ${tagName}`,
        href: location.pathname,
      },
    ]);
  };
  return (
    <>
      <Banner />
      <Page>
        <Helmeter
          title={`Тег ${tag} — Reminder`}
          metaDescription={`Тег ${tag} Reminder`}
          link={[{ rel: 'canonical', href: canonicalLink }]}
        />
        <JsonLd name={tag} breadcrumbs={breadcrumbs} posts={posts} />
        <Paginator>
          {(page, handleMeta, handleLoading) => (
            <TagPostList
              slug={tag}
              page={page}
              onMeta={handleMeta}
              onLoading={handleLoading}
              onTagLoaded={handleTagLoaded}
            />
          )}
        </Paginator>
      </Page>
    </>
  );
};

TagScreen.displayName = 'TagScreen';
export default TagScreen;
