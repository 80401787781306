import React, { FC, useEffect } from 'react';
import usePosts from '~/resources/usePosts';
import { ResourceMeta, QueryParams } from '~/resources';
import { PostType } from '~/resources/PostType';
import PreviewPost from './PreviewPost';
import c from './PostList.sass';
import ItemList from '../ItemList/ItemList';

interface TProps {
  authorId: string;
  page: number;
  onMeta: (meta: ResourceMeta) => void,
  onLoading: (loading: boolean) => void,
}

const AuthorPostList: FC<TProps> = ({
  page, onMeta, onLoading, authorId,
}) => {
  const params: QueryParams = {
    type: [PostType.ARTICLE, PostType.GUIDE],
    page: String(page)
  };
  params.author_id = authorId;

  const {
    meta, fetchBy: fetchPostsBy, data: posts, status,
  } = usePosts(params);
  useEffect(() => {
    fetchPostsBy();
  }, [page]);

  useEffect(() => {
    if (meta) {
      onMeta(meta);
    }
  }, [meta]);

  useEffect(() => {
    onLoading(!posts);
  }, [posts]);

  const items = () => {
    const items = [];
    if (page === 1) {
      items.push(<div className={c.rubric_name}>Статьи автора:</div>);
    }

    if (!posts) return items;

    posts.forEach((post) => {
      items.push(<PreviewPost post={post} showRubricLabel={false} />);
    });

    return items;
  };

  if (!posts) {
    return null;
  }

  return (
    <>
      {/* <Helmeter
        metaTitle={rubric?.meta_title ? `${rubric.meta_title} — Reminder` : `${rubric?.name} — Reminder`}
        metaDescription={rubric?.meta_description}
      /> */}
      <ItemList items={items()} />
    </>
  );
};

AuthorPostList.displayName = 'AuthorPostList';
export default AuthorPostList;
