import React, { FC, ReactElement, useEffect, useState } from 'react';
import { mailingSend, MailingStatus } from '~/resources/useMailing';
import { ymReachGoal } from '~/utils/ym';
import { setSubscriptionKey } from './utils';

interface TProps {
  children?: ReactElement;
  tags?: Array<string>;
  formClassName?: string;
  inputClassName?: string;
  buttonClassName?: string;
  inputPlaceholder?: string;
  inputDisabled?: boolean;
  onChangeStatus(status: MailingStatus): void;
  referrer?: string | null
}

const SubscriptionForm: FC<TProps> = ({
  children = 'Подписаться',
  tags = [],
  formClassName,
  inputClassName,
  buttonClassName,
  inputPlaceholder = 'e-mail',
  inputDisabled,
  onChangeStatus = null,
  referrer = null,
}) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (tags.includes('footer1')) {
      ymReachGoal('newsletters_footer_show');
    }
  }, []);

  const onEventYM = (popup: string, footer: string) => {
    if (tags.includes('popup1')) {
      ymReachGoal(popup);
    }
    if (tags.includes('newsletters1')) {
      ymReachGoal(footer);
    }
    // if (typeof window !== 'undefined' && typeof (window as any).ym !== 'undefined') {
    //   if (tags.includes('popup1')) {
    //     (window as any).ym(67715335, 'reachGoal', popup);
    //   }
    //   if (tags.includes('newsletters1')) {
    //     (window as any).ym(67715335, 'reachGoal', footer);
    //   }
    // }
  };

  const gaEvent = (eventAction: ('send_error' | 'send_success')) => {
    if (typeof window !== 'undefined' && typeof (window as any).dataLayer !== 'undefined') {
      (window as any).dataLayer.push({
        event: 'sp_event',
        eventCategory: 'subscription',
        eventAction,
      });
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onEventYM('newsletter_popup_submit', 'newsletter_footer_submit');
    const body: {
      [key: string]: any;
    } = { email, tags };
    if (referrer) {
      body.referrer = referrer;
    }
    const r = await mailingSend(body);
    if (r.data && r.data.status === 'ok') {
      gaEvent('send_success');
      setSubscriptionKey();
      onEventYM('newsletter_popup_success', 'newsletter_footer_success');
    } else {
      gaEvent('send_error');
      onEventYM('newsletter_popup_error', 'newsletter_footer_error');
    }
    if (onChangeStatus) {
      onChangeStatus(r.data ? r.data.status : MailingStatus.ERROR);
    }
  };

  const onInvalid = () => {
    gaEvent('send_error');
  };

  return (
    <form className={formClassName} onSubmit={onSubmit} onInvalid={onInvalid}>
      <input
        className={inputClassName}
        type="email"
        name="email"
        required
        disabled={inputDisabled}
        placeholder={inputPlaceholder}
        aria-label="Укажите свой email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button
        className={buttonClassName}
        type="submit"
        aria-label="Подписаться на рассылку"
      >
        {children}
      </button>
    </form>
  );
};

SubscriptionForm.displayName = 'SubscriptionForm';
export default SubscriptionForm;
