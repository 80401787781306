import React, {
  ChangeEvent, FC, FormEvent, useEffect, useState,
} from 'react';
import config from '~/config';
import validateEmail from '~/utils/email';
import anyFetch from '~/resources/anyFetch';
import disableScroll from '~/utils/disableScroll';
import CommonLink from '~/components/Links/CommonLink';
import c from './Auth.sass';
import { AuthModalFrom, AuthModalState, usePageContext } from '../page/context.page';
import Socials from './Socaials';
import Loader from '../Loader';

interface CheckFormData {
  email: string
}

const URL = `${config('API_HOST')}/auth/check`;

interface TProps {
  onEmailChanged: (email: string) => void;
  email?: string;
  from: AuthModalFrom;
}

const CheckForm: FC<TProps> = ({ onEmailChanged, email: defaultEmail, from }) => {
  const [formData, setFormData] = useState<CheckFormData>({
    email: defaultEmail || '',
  });
  const [isEmailInvalid, setIsEmailInvalid] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { authModal, alertModal } = usePageContext();

  const onChange = (e: ChangeEvent<HTMLInputElement>, value?: unknown) => {
    const { name, value: eventValue } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value ?? eventValue }));
    setIsEmailInvalid(!validateEmail(eventValue));
    setShowError(false);
    onEmailChanged(eventValue);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email } = formData;
    if (!validateEmail(email)) {
      setIsEmailInvalid(true);
      setShowError(true);
      return;
    }
    setIsLoading(true);
    const res = await anyFetch(URL, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });
    const resData = await res.json();
    setIsLoading(false);

    if (authModal && resData && alertModal) {
      // TODO переписать на глобальный modal
      disableScroll(true);

      if (resData?.result) {
        authModal[1]({ authModalState: AuthModalState.SIGNIN, authModalFrom: from });
      } else {
        authModal[1]({ authModalState: AuthModalState.SIGNUP, authModalFrom: from });
      }
    }
  };

  useEffect(() => {
    if (defaultEmail) {
      setIsEmailInvalid(!validateEmail(defaultEmail));
    }
  }, []);

  return (
    <>
      <div className={c.title}>
        { from === AuthModalFrom.NONE
          ? 'Войдите или зарегистрируйтесь'
          : 'Войдите или зарегистрируйтесь, чтобы подписаться' }
      </div>
      <form
        noValidate
        className={c.form}
        onSubmit={onSubmit}
      >
        <input
          type="email"
          name="email"
          placeholder="Ваш e-mail"
          aria-label="Email"
          value={formData.email}
          onChange={onChange}
          className={`${c.inputText} ${!formData.email ? c.inputText_empty : ''} ${isEmailInvalid ? c.inputText_invalid : ''}`}
          required
        />
        {showError && (
          <span
            className={c.error}
          >
            Ваш e-mail не полон
          </span>
        )}
        <button
          type="submit"
          aria-label="Продолжить"
          className={
            isEmailInvalid
              ? `${c.btn} ${c.btn_disabled}`
              : `${c.btn} ${c.btn_enabled}`
          }
        >
          Продолжить
        </button>
      </form>
      <div className={c.line}>
        <div className={c.or}>или</div>
      </div>
      <Socials />
      <div className={c.service_text}>
        Регистрируясь, вы принимаете условия
        <br />
        <CommonLink to="/privacy_policy" target="_blank"><u>пользовательского соглашения</u></CommonLink>
      </div>
      {isLoading && (
        <div className={c.modal__loading}>
          <Loader />
        </div>
      )}
    </>
  );
};

CheckForm.defaultProps = {
  email: undefined,
};

CheckForm.displayName = 'CheckForm';
export default CheckForm;
