import React, { FC } from 'react';
import CommonLink from '~/components/Links/CommonLink';
import { useConfig } from '~/resources';
import { Post } from '~/resources/models';
import Image from '../Image';
import Topic from '../Topic';
import c from './PreviewPost.sass';

type TProps = {
    post: Post;
}

const PreviewLongreadPost: FC<TProps> = ({
  post,
}) => {
  const {
    topic, published_at, title, description,
    image_preview, preview_mode = 'preview_longread', slug,
  } = post;
  const { data: config } = useConfig();
  const imageConfig = config ? config.images : {};

  const urlPost = `/longread/${slug}`;
  const urlTopic = topic && `/topic/${post.topic.slug}`;

  return (
    <div className={`${c.container} ${c[`container_${preview_mode}`]}`}>
      <div className={c.content}>
        {(topic && topic.title) && (
        <div className={c.top}>
          <Topic
            title={topic.title}
            subtitle={new Date(+published_at * 1000).toLocaleDateString('ru')}
            href={urlTopic}
          />
        </div>
        )}
        <CommonLink to={urlPost} className={c.title}>{title}</CommonLink>
        {description && (
        <div className={c.description}>{description}</div>
        )}
      </div>
      <div className={c.imageWrap}>
        {description && (
        <div className={`${c.description} ${c.description_mob}`}>{description}</div>
        )}
        {image_preview && (
          <CommonLink to={urlPost}>
            <Image
              {...image_preview}
              mode={preview_mode}
              className={c[`place-${preview_mode}`]}
              imageConfig={imageConfig}
              contentEditable={undefined}
              alt={image_preview.alt || title}
            />
          </CommonLink>
        )}
      </div>
    </div>
  );
};

PreviewLongreadPost.displayName = 'PreviewLongreadPost';
export default PreviewLongreadPost;
