import React, { FC } from 'react';

import Title from '~/components/typography/Title';
import SubscriptionFormNew from '~/components/PaidSubscription/ui/SubscriptionFormNew';
import { SubscriptionPlan } from '~/resources/models';
import Button from '~/components/Button/Button';
import Paragraph from '~/components/typography/Paragraph';
import GiftIcon from '~/components/icons/Gift';
import CommonLink from '~/components/Links/CommonLink';
import c from './SubscriptionHeader.sass';

type TProps = {
  plans: SubscriptionPlan[];
  onSubmit: (plan: SubscriptionPlan) => void;
}

const SubscriptionHeader: FC<TProps> = ({ plans, onSubmit }) => {
  const plansSorted = plans.sort((a, b) => b.id - a.id);

  return (
    <div className={c.subscription_header}>
      <div className={c.titles_container}>
        <Title level={2} className={c.main_title}>
          Пространство личного развития
        </Title>
        <Title level={4} className={c.subtitle}>Подписка на статьи, гайды и сообщество</Title>
      </div>
      <SubscriptionFormNew plans={plansSorted} onSubmit={onSubmit} />
      <CommonLink to="/gift">
        <Button variant="secondary" size="large" className={c.gift_button}>
          <Paragraph className={c.gift_button__label}>Подарите годовую подписку Reminder</Paragraph>
          <GiftIcon />
        </Button>
      </CommonLink>
    </div>
  );
};

SubscriptionHeader.displayName = 'SubscriptionHeader';
export default SubscriptionHeader;
